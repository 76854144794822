import React, { useEffect, useState } from 'react';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Logo1 from '../public/img/edf.png';
import Logo2 from '../public/img/care.png';
import Logo3 from '../public/img/danone.png';
import Logo4 from '../public/img/giz.png';
import LogoBlanc from '../public/img/ecosystem.png';
import background3 from '../public/img/background3.png';
const Footer = ({ login,modalOpen }) => {
  const screens = useBreakpoint();
  const items = [
    {
      name: 'ecosystem',
      link: 'https://www.linkedin.com/company/danone-ecosystem/',
      logo: LogoBlanc.src
    },
    {
      name: 'care',
      link: 'https://www.caremaroc.org/',
      logo: Logo2.src
    },
    {
      name: 'edf',
      link: 'https://www.edf.org/',
      logo: Logo1.src
    },
    {
      name: 'danone',
      link: 'https://www.danone.com/fr.html',
      logo: Logo3.src
    },
    {
        name: 'giz',
        link: 'https://www.giz.de/en/html/index.html',
        logo: Logo4.src,
        taille: "65px",
        hauteur: "50px !important"
      },
  ];
  return (
    <div
      className="container"
      style={{
        maxWidth: login && '870px',
        height: login && '100',
        margin: login && 'auto'
      }}
    >
      <div>
        <ul className="list">
          {items.map((item, index) => (
            <li key={index}>
              <div className="list-item">
                <a href={item.link} target="_blank">
                  <img src={item.logo} alt={item.name} width={item.taille} height={item.hauteur}/>
                </a>
              </div>
            </li>
          ))}
        </ul>
      </div>
      <style jsx>{`
        .container {
          background-image: ${!login ? `url(${background3.src})` : 'none'};
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
        //   padding-top: ${screens.sm ? '80px' : '14px'};
        }
        ul {
          list-style-type: none;
          padding: 0;
          display: flex;
            flex-direction: ${screens.sm ? 'row' : 'column'};
          justify-content: space-evenly;
          flex-wrap: ${login ? '' : 'wrap-reverse;'};
          align-items: center;
        }
        li {
        //   flex: ${screens.md ? '20%' : '100%'};
        //   max-width: ${screens.md ? '33.33%' : '100%'};
          margin: 0 0 16px;
        }

        .list-item {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          padding-top: ${modalOpen ? "370px" : "30px"};
        }
        .list-item img {
          max-width: 150px;
        //   height: 38px;
          margin: 0 auto 16px;
        
        }
       
        // .footer {
        //   display: flex;
        //   flex-direction: column;
        //   // justify-content: space-between;
        // }
        // .footerTop {
        //   display: flex;
        //   justify-content: center;
        //   // align-items: center;
        //   margin-bottom: 32px;
        // }
        // .footerTop img {
        //   max-width: 250px;
        //   height: auto;
        // }
        // .list {
        //   display: flex;
        //   flex-direction: ${screens.md ? 'row' : 'column'};
        //   justify-content: space-between;
        //   padding: 0;
        //   flex-wrap: wrap;
        // }
        .footerBottom {
          display: flex;
          // flex-direction:  ${screens.md ? 'row' : 'row'};
          justify-content: center;
          align-items: center;
          margin-bottom: 32px;
        }
        .footerBottom img {
          max-width: 250px;
          height: auto;
          
        }
        .list .footerBottom img:last-of-type {
          border-radius: 60px;
        }
      `}</style>
    </div>
  );
};

export default Footer;

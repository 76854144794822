import React, { useEffect } from 'react';
import Footer2 from '../components/Footer2';
import background1 from '../public/img/background1.png';
import LogoBlanc from '../public/img/ecosystem.png';
import { Layout, Form, Button, Input, Modal } from 'antd';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import { useRouter } from 'next/router';
import LogoColor from '../public/img/LogoColor.png';
import Cookies from 'js-cookie';
function login() {
  const screens = useBreakpoint();
  const [login, setLogin] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [modalOpen, setModalOpen] = React.useState(false);
  let router = useRouter();
  const connectUser = async (email) => {
    console.log(email);

    return new Promise((resolve, reject) => {
      fetch('/api/login', {
        method: 'post',
        body: JSON.stringify({
          email: email.email
        }),
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        }
      })
        .then((res) => {
          if (res.status === 200) {
            resolve(res.json());
          } else {
            reject(res);
          }
        })
        .catch((err) => {
          reject(err);
        });
    });
  };
  const onFinish = (email) => {
    if (email) {
      setLoading(true);
      connectUser(email)
        .then((res) => {
          Cookies.set('conected', email.email);
          console.log(res.status);
          setLogin(true);
          // router.replace('/live');
        })
        .catch((err) => {
          if(err){
            setModalOpen(true);
          }
          // setModalOpen(true);
          setLoading(false);
          console.log('erreur mon poste');
        });
    }else{
      setModalOpen(true);
    }
  };
  useEffect(() => {
    if (Cookies.get('conected')) {
      router.push('/live');
    }
  }, [login]);
  // const onFinish = () => {
  //   console.log('Success:');
  // }
  return (
    <Layout>
      <div className="container">
        <div
          style={{
            maxWidth: '950px',
            margin: '0 auto'
          }}
        >
          <div className="logo">
            <img src={LogoBlanc.src} width="25%" />
          </div>
          <div className="title">
            <h1>Webinar</h1>
          </div>
          <div className="planning">
            <p>March | 20th</p>
            <p> 3:00-4:00pm CET • 9:00-10:00pm CST • 10:00-11:00pm EST </p>
          </div>
          {!modalOpen && (
            <>
              <div className="description">
                <p>Putting smallholders farmers <br/> at the center of sustainable food systems</p>
              </div>
              <Form className="form-login" onFinish={onFinish}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: 'Please input your email!'
                    }
                  ]}
                >
                  <Input
                    style={{
                      width: screens.md ? '40%' : '80%'
                    }}
                    placeholder="Enter your email to access to the webinar"
                  />
                </Form.Item>
                <Form.Item
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    flexDirection: 'row',
                  }}
                >
                  <Button type="primary" htmlType="submit" loading={loading}>
                    Login
                  </Button>
                  <br/>
                <a href="/register">Not registred ? Sign up here</a>
                </Form.Item>
              </Form>
            </>
          )}
          
        </div>
        <Footer2 login={true} modalOpen={modalOpen} />
      </div>
      <Modal
            style={{ top: 310 }}
            width={'90%'}
            open={modalOpen}
            onCancel={() => setModalOpen(false)}
            onClose={() => setModalOpen(false)}
            center
            footer={null}
          >
            <p
              style={{
                fontSize: 20,
                marginTop: 20
              }}
            >
              Oops...
            </p>
            <p
              style={{
                fontSize: 20
              }}
            >
              It seems you are not registered yet! <br /> Please register, you
              will then be redirected to the live
            </p>

            <Button
              onClick={() => {
                router.replace('/register');
              }}
              type="primary"
              style={{
                fontSize: 20,
                padding: '2px 30px',
                marginTop: 20
              }}
            >
              Click here to register
            </Button>

            <div
              style={{
                marginTop: 40
              }}
            >
              <img src={LogoColor.src} width="22%" height={'100%'} />
            </div>
          </Modal>
      <style jsx>{`
        .container {
          background-image: url(${background1.src});
          background-size: cover;
          background-repeat: no-repeat;
          background-position: center;
          padding: 32px 0 0;
          color: white;
          text-align: center;
          height: ${screens.sm ? '100vh' : ''};
        }
        .logo {
          display: flex;
          justify-content: end;
          margin-right: 20px;
        }
        .title > h1 {
          text-align: center;
          color: white !important;
          font-size: 40px;
          // font-weight: bold !important;
          margin: 60px 0 0 0;
        }
        .planning {
          text-align: center;
          color: white !important;
          font-size: 20px;
          margin: 0;
          padding-top: 25px;
        }
        .planning > p:first-child {
          margin: 0;
        }
        .description {
          text-align: center;
          color: white !important;
          font-size: 25px;
          margin: 52px 0 52px;
          font-weight: bold;
        }
        .description > p {
          margin: 0;
        }
        .description > p:last-child {
          font-weight: normal;
          font-size: 20px;
          padding-top: 20px;
        }
        .container > .form {
          width: 33% !important;
          margin: auto !important;
        }
        .ant-form-item > .ant-form-item-control-input-content {
          margin-bottom: 0 !important;
          width: 33% !important;
        }
        .ant-modal {
          width: 1000px !important;
        }
        .ant-modal-content .ant-modal-body p {
          font-size: 20px !important;
        }
         a {
          color: white;
          font-size: 11px;
          text-decoration: underline;
        }
      `}</style>
    </Layout>
  );
}

export default login;
